import { GetUserInfoApi } from "@/request/api"

export default {
    namespaced: true,
    state: {
        userInfo:JSON.parse(localStorage.getItem('edb_userInfo'))|| {
            permissions: [],
            roles: [],
            user: {}
        }
    },
    mutations: {
        async changeUserInfo(state,payload) {
            state.userInfo = payload
            localStorage.setItem("edb_userInfo",JSON.stringify(state.userInfo))
        }
    },
    actions: {
        async asyncChangeUserInfo({ commit }) {
            let GetUserInfoApuRes = await GetUserInfoApi()
            console.log('GetUserInfoApuRes', GetUserInfoApuRes);
            commit('changeUserInfo', { 
                permissions:GetUserInfoApuRes.permissions,
                roles:GetUserInfoApuRes.roles,
                user:GetUserInfoApuRes.user
            })
        }
    }
}