export default{
    namespaced:true,
    state:{
        menuData:[]
    },
    mutations:{
        changeMenuData(state,payLoad){
            state.menuData=payLoad
        }
    }
}