import Vue from 'vue'
import VueRouter from 'vue-router'
import { GetUserRoutersApi } from "@/request/api"
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mainlayout',
    component: () => import('../views/layout/MainLayout.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import(`../views/Home.vue`),
        meta: { title: ['首页', '首页1'] }
      },
      {
        path: '/about',
        component: () => import(`../views/about`),
        meta: { title: ['米悦首页', '关于我们'] }
      },
      {
        path: '/Bestselling',
        component: () => import(`../views/Bestselling`),
        meta: { title: ['米悦首页', '畅销展示'] }
      },
      {
        path: '/join',
        component: () => import(`../views/join`),
        meta: { title: ['米悦首页', '加盟咨询'] }
      },
      {
        path: '/Headlines',
        component: () => import(`../views/Headlines`),
        meta: { title: ['米悦首页', '米悦头条'] }
      }
    ]
  },
  // {
  //   path: '/login',
  //   component: () => import('../views/loginView.vue')
  // },
  {
    path: '/login',
    component: () => import('../views/about')
  },
  {
    path: '/upload',
    component: () => import('../views/upload')
  }
  // {
  //   path: '*',
  //   component: () => import('../views/404.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})

// router.beforeEach(async (to, from, next) => {
//   let token = localStorage.getItem('edb-token')
//   //管理系统创建的逻辑
//   if (to.path === '/login' && token) {
//     next('/')
//     return
//   }

//   if (to.path !== '/login' && !token) {
//     next('/login')
//   }

//   if (token && store.state.userMenuData.menuData.length == 0) {
//     let GetUserRoutersApiRes = await GetUserRoutersApi()
//     if (!GetUserRoutersApiRes) return;

//     console.log('路由信息', GetUserRoutersApiRes);

//     let newUserMenuData = [{ title: '首页', path: "/home" }]
//     let ret = GetUserRoutersApiRes.data.map(item => {
//       if (item.children) {
//         return {
//           title: item.meta.title,
//           path: item.path,
//           children: item.children.map(sitem => {
//             return {
//               title: sitem.meta.title,
//               path: item.path + '/' + sitem.path,
//             }
//           })
//         }
//       } else {
//         return {
//           title: item.meta.title,
//           path: item.path
//         }
//       }
//     })
//     newUserMenuData = [...newUserMenuData, ...ret]
//     console.log(newUserMenuData);
//     store.commit('userMenuData/changeMenuData',newUserMenuData)

//     //路由的路径
//     let RoutersData=[{path:"/home",component: () => import('../views/Home.vue'),meta:{title:['首页']}},
//     {path:"/profile",component: () => import('../views/Profile.vue'),meta:{title:['个人中心']}}]
//     GetUserRoutersApiRes.data.forEach(item => {
//       let ret=item.children.map(sitem=>{
//         return{
//           path: item.path + '/' + sitem.path,
//           component: () => import(`../views${item.path}/${sitem.name}.vue`),
//           meta:{title:[item.meta.title,sitem.meta.title]}
//         }
//       })
//       RoutersData=[...RoutersData,...ret]
//     });
//     console.log('RoutersData',RoutersData);

//     RoutersData.forEach(item=>{
//       router.addRoute("mainlayout",item)
//     })

//     next(to.path)
//     return

//   }



//   next()
// })

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
}

export default router
