import axios from 'axios'
import { Message } from 'element-ui'
import router from "@/router"

const instance = axios.create({
    baseURL: "http://xue.cnkdl.cn:23683",
    timeout: 10000
})

//请求拦截器
instance.interceptors.request.use(config => {
    const token = localStorage.getItem('edb-token')
    if (token && !config.url.endsWith("/login") && !config.url.endsWith("/captchaImage")) {
        config.headers["Authorization"] = "Bearer " + token //http的规范必须携带Bearer 的格式
    }
    return config
}, err => {
    return Promise.reject(err)
})

//响应拦截器
instance.interceptors.response.use(res => {
    var res_data = res.data
    if (res_data.code&&res_data.code != 200) {
        Message.error(res_data.msg || '网络请求错误');

        if(res_data.code==401){
            localStorage.removeItem('edb-token')
            router.push('/login')
        }

        return false
    }

    return res_data
}, err => {
    return Promise.reject(err)
})

export default instance